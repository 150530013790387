<div class="block-contain min-h-screen overflow-hidden bg-bottom bg-no-repeat bg-contain">

  <div class="mx-auto container">
    
    <header class="mt-11">
      <nav class="mx-auto flex max-w-9xl items-center justify-end p-2 lg:px-8" aria-label="Global">
        <div class="flex lg:flex-1 justify-end">
          <a href="#" class="-m-1.5 p-1.5">
            <span class="sr-only">Hola Doc</span>
            <img class="h-17 w-auto" src="assets/holaDocLogo.png" alt="Hola Doc">

          </a>
        </div>
      </nav>
    </header>
    <div class="body-mail flex flex-col mt-14">
      <div class="left-section w-full text-center"> <!-- style="border: 1px solid red;" -->
        <div class="flex flex-col justify-center">

          @if ( emailSuccess ) {
            <div class="center-logo w-full mb-5 flex flex-col justify-center items-center">
              <img class="w-28 logo-success" src="assets/logos/blue-check-svg.svg" alt="exitoso">
            </div>
  
            <div class="success-main w-full mb-5 flex flex-col">
              <p class="font-bold text-lg text-headers-blue">¡Listo!</p>
            </div>
  
            <div class="success-body-one w-full mb-5 flex flex-col text-[#080047]">
              <p>Tu recurso digital ya está en camino</p>
            </div>
  
            <div class="success-body-two w-full mb-14 justify-center items-center flex flex-col">
              <p class="w-2/5 text-[#252B35]">Revisa tu correo electrónico para acceder al contenido y descubre cómo la telemedicina de HolaDOC puede transformar tu bienestar.</p>
            </div>
  
            <div class="w-full mb-5 justify-center items-center flex flex-col">
              <button
                (click)="goToAnotherResource()"
                type="button" 
                class="w-56 font-bold rounded-full text-sm px-5 py-2.5 me-2 mb-2 bg-white border-[#1A55A1] border-2 ">
                <span class="text-[#1A55A1]">
                  Descargar otro recurso
                </span>
              </button>
            </div>
          }
          @else {
            <div class="w-full mb-5 flex flex-col justify-center items-center">
              <img class="logo-failed w-32" src="assets/logos/blue-warning-svg.svg" alt="fallido">
            </div>
  
            <div class="w-full mb-5 flex flex-col">
              <p class="font-bold text-lg text-headers-blue">¡Lo sentimos!</p>
            </div>
  
            <div class="w-full mb-5 flex flex-col text-[#080047]">
              <p>Tuvimos inconvenientes para guardar tus datos. </p>
            </div>
  
            <div class="w-full mb-8 justify-center items-center flex flex-col">
              <p class="w-1/2 text-[#252B35]">
                Intenta nuevamente o contáctanos a soporte&#64;holadoc.com
              </p>
            </div>
  
            <div class="w-full mb-5 justify-center items-center flex flex-col">
              <button
                (click)="goToReintent()"
                type="button" 
                class="w-56 font-bold rounded-full text-sm px-5 py-2.5 me-2 mb-2 bg-transparent border-[#1A55A1] border-2 ">
                <span class="text-[#1A55A1]">
                  Reintentar
                </span>
              </button>
            </div>
  
            <div class="w-full mb-5 justify-center items-center flex flex-col">
              <button
                (click)="goToHomeFromError()"
                type="button" 
                class="w-56 font-bold rounded-full text-sm px-5 py-2.5 me-2 mb-2 bg-transparent border-[#1A55A1] border-0 ">
                <span class="text-[#1A55A1]">
                  Regresar
                </span>
              </button>
            </div>
          }

        </div>
      </div>
    </div>
  </div>

</div>
