import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HomeService {

    private urlApi: any = environment.base_url
    private headers = { headers: { Accept: "application/json", token: '' } }
    
    constructor(
        private http: HttpClient
    ) {}

    goToRegister(data: any) {
        return this.http.post( `${this.urlApi}/patients/create-digital-resource`, data, this.headers )
    }

    getIncentives() {
        return this.http.get( `${this.urlApi}/incentive`, this.headers )
    }

    getDigitalAccess() {
        return this.http.get( `${this.urlApi}/digital-resource/list`, { headers: { Accept: "application/json" } } )
    }
}