import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalComponent } from '../modal/modal.component';
import { HomeService } from '../../core/services/home.service';
import { Router, ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { Observable } from 'rxjs';
import { finalize, map, startWith } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { SUPPORTED_COUNTRIES } from '../../../common/models/supported-countries';
import { CleverTapService } from '../../core/services/clevertap.service';

interface responseDigitalAssets {
  createdAt: string,
  document: string,
  img: string,
  name: string,
  publicationDate: string,
  status: number,
  updatedAt: string,
  _id: string
}

interface incentives {
  createdAt: string,
  highlightedText: string,
  img: string,
  status: number,
  text: string,
  updatedAt: string,
  _id: string
}

interface flags {
  value: string,
  phoneCode: string,
  name: string,
  icon: string,
  svgIcon: string
  newSvgIcon: string
  id: string
}

enum statusIncentive {
  activate = 1,
  deactivate = 3,
}

const lengthTypeNumber: any = {
  "identificationNumber": {
    "min": 5,
    "max": 8,
  },
  "phoneNumber": 11
}

enum timeInMiliseconds {
  modal = 2500
}


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  nonValidateKeys = ['Backspace', 'Delete', 'ArrowLeft', 
    'ArrowRight', 'ArrowUp', 'ArrowDown', 'Tab', 
    'Enter', 'Escape', 'Home', 'End', 'Control', 
    'Shift', 'Alt', 'CapsLock', 'Meta', 'Insert', 
    'F1', 'F2', 'F3', 'F4', 'F5', 'F6', 'F7', 'F8', 
    'F9', 'F10', 'F11', 'F12'
  ];

  countries = SUPPORTED_COUNTRIES

  TIME_TO_DELAY_MODAL = timeInMiliseconds.modal;
  
  subscriberNgForm!: FormGroup
  mycontrol = new FormControl('');

  sendForm:boolean = false;
  SelectDigitalResource = true;
  labelDigitalResource = true;
  whyDigitalResource = '';
  selectCountryCode = false;
  
  reintent: boolean = false;
  success: any;
  
  public assetsUrl: any = environment.assets_url
  checkEd = false;

  digitalAssets: Array<responseDigitalAssets> = []
  incentives: Array<incentives> = []
  activateIncentive: any

  filteredOptions!: Observable<flags[]>;

  selectedFlag = 've'
  wichFlagIs = ''
  idFlag: string = '';

  countSlides = 0
  countClicks = 1

  test = 'Selecciona'

  defaultCountryCodeVenezuela = "58"

  @ViewChild('SwiperContainerDesktop') swiperContainer!: ElementRef

  constructor(
    private dialog: MatDialog,
    private homeServices: HomeService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private readonly clevertap: CleverTapService
  ) { }

  ngOnInit() {

    if ( localStorage.getItem('userForm') ) {
      const { savedDate } = JSON.parse(localStorage.getItem('userForm')!);
      
      if ( savedDate ) {
        const hoursDifference = moment().diff( moment(savedDate), 'hours' );

        if (  hoursDifference > 24 ) {
          localStorage.removeItem('userForm');
        }
        else {
          const { idFlag } = JSON.parse(localStorage.getItem('userForm')!);
          this.idFlag = idFlag
        }

      }
      else {

        this.idFlag = this.countries.find(country => country.phoneCode === '+58')?.id || '';
      }
      
    }
    else {
      this.idFlag = this.countries.find(country => country.phoneCode === '+58')?.id || '';
    }

    this.activateRoute.queryParams.subscribe( params => {
      if ( params['reintent'] ) {
        this.reintent = true;
      }
      this.success = params['success'];
    });

    this.filteredOptions = this.mycontrol.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '') )
    )

    this.findDigitalAccess();
    this.findIncentive();
    this.hideNavigationButtons();

    this.subscriberNgForm = new FormGroup({
      digitalResource: new FormControl("", Validators.required),
      firstName: new FormControl("", Validators.required),
      lastName:  new FormControl("", Validators.required),
      identificationNumber: new FormControl("", Validators.required),
      email: new FormControl("", [Validators.required, Validators.email]),
      countryCode: new FormControl(this.defaultCountryCodeVenezuela, Validators.required),
      phoneNumber: new FormControl("", Validators.required),
      check: new FormControl(false, Validators.requiredTrue)
    });

    if ( this.reintent ) {
      this.initFormReintent();
    }

    this.subscriberNgForm.statusChanges.subscribe(status => {
      if (status === 'VALID') {
        this.enableOrDisabledSubmitButton('enabled');
      } else {
        this.enableOrDisabledSubmitButton('disabled');
      }
    });

  }

  enableOrDisabledSubmitButton (statusForm: any) {
    if (statusForm === 'enabled' ) {
      this.sendForm = true;
    }
    else {
      this.sendForm = false;
    }
  }

  hideNavigationButtons () {

    const hostElement = document.getElementById('swiperContainerDesktop');
    const hostElementMobile = document.getElementById('swiperContainerMobile');
    
    if ( hostElement?.shadowRoot ) {
      const buttonNext = hostElement.shadowRoot.querySelector(`.swiper-button-next`) as HTMLElement;
      const buttonPrev = hostElement.shadowRoot.querySelector(`.swiper-button-prev`) as HTMLElement;

      if ( buttonNext ) {
        buttonNext.style.opacity = '0'
      }

      if ( buttonPrev ) {
        buttonPrev.style.opacity = '0'
      }
    }

    if ( hostElementMobile?.shadowRoot ) {
      const buttonNext = hostElementMobile.shadowRoot.querySelector(`.swiper-button-next`) as HTMLElement;
      const buttonPrev = hostElementMobile.shadowRoot.querySelector(`.swiper-button-prev`) as HTMLElement;

      if (buttonNext) {
        buttonNext.style.opacity = '0'
      }

      if (buttonPrev) {
        buttonPrev.style.opacity = '0'
      }
    }

  }

  private _filter(value: string): any {
    const filterValue = value.toLowerCase();
    return this.countries.filter(country => country.name.toLowerCase().includes(filterValue) );
  }

  initFormReintent() {

    const rawForm = localStorage.getItem('userForm');

    if ( rawForm ) {

      let {
        digitalResource,
        firstName,
        lastName,
        identificationNumber,
        email,
        countryCode,
        phoneNumber,
        idFlag
      } = JSON.parse(rawForm);

      this.selectedFlag = this.countries.find(country => country.id === idFlag)?.newSvgIcon || '';
      this.wichFlagIs = countryCode;

      phoneNumber = Number(phoneNumber);
      identificationNumber = identificationNumber;

      console.log(this.success);
      console.log(this.selectedFlag);
      
      if ( this.success == 'false' ) {
        console.log("exitoso");
        
        this.labelDigitalResource = false;
        this.whyDigitalResource = digitalResource;
        // this.SelectDigitalResource = true;
        this.sendForm = true;
      }
      

      this.subscriberNgForm = new FormGroup({
        digitalResource: new FormControl(digitalResource, Validators.required),
        firstName: new FormControl(firstName, Validators.required),
        lastName:  new FormControl(lastName, Validators.required),
        identificationNumber: new FormControl(identificationNumber, Validators.required),
        email: new FormControl(email, [Validators.required, Validators.email]),
        countryCode: new FormControl(countryCode, Validators.required),
        phoneNumber: new FormControl(phoneNumber, Validators.required),
        check: new FormControl(true, Validators.requiredTrue)
      });

    }
    else {
      this.subscriberNgForm = new FormGroup({
        digitalResource: new FormControl("", Validators.required),
        firstName: new FormControl("", Validators.required),
        lastName:  new FormControl("", Validators.required),
        identificationNumber: new FormControl("", Validators.required),
        email: new FormControl("", [Validators.required, Validators.email]),
        countryCode: new FormControl(this.defaultCountryCodeVenezuela, Validators.required),
        phoneNumber: new FormControl("", Validators.required),
        check: new FormControl(false, Validators.requiredTrue)
      });
    }
  }

  findDigitalAccess() {
    this.homeServices.getDigitalAccess().subscribe({
      next: (res: any) => {
        console.log(res);
        const activateResource = res.data.filter((ar: any) => ar.status == statusIncentive.activate)
        this.countSlides = activateResource.length;
        this.digitalAssets = activateResource
      },
      error: (err) => {
        console.log("ERROR BUSCANDO LOS RECURSOS");
        console.log({err});
      }
    })
  }


  findIncentive() {
    this.homeServices
      .getIncentives()
      .subscribe({
      next: (res: any) => {
        console.log(res);
        if ( res.data.length ) {
          this.incentives = res.data
          this.checkHaveModal();
        } 
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
        console.log("INCENTIVO");
      },
    })
    
  }
  handleClickPrev() {
    const prevButton = document.querySelector('.swiper-button-prev'); // Obtiene el elemento DOM del botón
    
    console.log({prevButton});
    // const shadowRoot = this.swiper.nativeElement.shadowRoot;
    
    // if (prevButton) {
    //   this.renderer.dispatchEvent(prevButton, new Event('click')); // Dispara un evento de clic
    // } else {
    //   console.error('No se encontró el botón .swiper-button-prev');
    // }
  }

  checkHaveModal() {
    this.activateIncentive = this.incentives.find(incentive => incentive.status == statusIncentive.activate); 

    if ( this.activateIncentive ) {
      this.openModal();
    }
  }


  openModal() {

    if ( this.activateIncentive ) {
      setTimeout(() => {
        this.dialog.open(ModalComponent, {
          // width: '90',
          height: '70%',
          maxWidth: '100%',
          closeOnNavigation: false,
          panelClass: 'custom-container',
          data: this.activateIncentive,
          backdropClass: "blur",
          autoFocus: false,
        })  
      }, this.TIME_TO_DELAY_MODAL);
      
    }
  }


  itemSeleccionado(selectedValue: string) {
    if ( selectedValue ) {
      this.subscriberNgForm.get('digitalResource')?.setValue(selectedValue);
      this.subscriberNgForm.get('digitalResource')?.updateValueAndValidity();
      this.labelDigitalResource = false;

    }
  }

  toggleDropdown() {
    // this.isOpen = !this.isOpen;
  }
  sendEmail() {
    
    let form = this.subscriberNgForm.value
    delete form.check;
    const cedula = String(form['identificationNumber']);
    
    if ( !form.digitalResource ) {
      this.SelectDigitalResource = false
    }
    
    if ( this.subscriberNgForm.invalid ) {

      this.subscriberNgForm.markAllAsTouched();
      this.subscriberNgForm.updateValueAndValidity();
      return;
    }
    
    if ( this.wichFlagIs ) {
      if ( this.wichFlagIs === this.defaultCountryCodeVenezuela  ) {
        if ( cedula.length < 5 || cedula.length > 8 ) {
          this.subscriberNgForm.get('identificationNumber')?.setErrors({ 'lengthLocal': true });
          return;
        }
      }
      else {
        console.log("entro aqui", cedula);
        if ( cedula.length < 1 || cedula.length > 50 ) {
          this.subscriberNgForm.get('identificationNumber')?.setErrors({ 'lengthForeign': true }); 
          return;
        }
      }
    }
    else {
      if ( cedula.length < 5 || cedula.length > 9 ) {
        this.subscriberNgForm.get('identificationNumber')?.setErrors({ 'lengthLocal': true });
        return;
      }
    }

    const newForm: any = {}

    for (let [index,_] of Object.entries(form) ) {
      newForm[index] = String(form[index]);
    }
   
    this.submitForm(newForm)
  }

  submitForm( form:any ) {

    form['idFlag'] = this.idFlag;
    form['savedDate'] = new Date().toISOString();
    console.log({form});
    this.homeServices.goToRegister(form).pipe(
      finalize(() => {
        localStorage.setItem('userForm', JSON.stringify(form));
      })
    ).subscribe({
      next: (res: any) => {
        console.log(res);
        this.clevertap.registerEvent$('data_collected', {
          user_id: form.identificationNumber,
          resource_id:form.digitalResource,
        });
        const { data: { _id } } = res
        if ( _id ) {
          this.router.navigate( ['/serviceEmail'], { 
            queryParams: 
            { 
              'status': true, 
              token: _id 
            } 
          })
        }
      },
      error: (error) => {
        console.log(error);
        this.router.navigate(['/serviceEmail'], { 
          queryParams: { 
            'status': false, 
            token: '12345' 
          }
        })
      }
    })

  }

  cheked(event: any) {

    this.checkEd = event.target.checked;
  }

  findCountry(event: any){
    console.log(event);
    
  }

  navigateClick( nameClass: any, shadowMove: boolean = false, howManySlides: number = 0 ) {
    const hostElement = document.getElementById('swiperContainerDesktop');
    
    if ( hostElement?.shadowRoot ) {

      /** note:
       * cuando hay un -prev, -active, -next
       * estoy en medio de los sliders
       * 
       * si solo hay un -active, -next
       *  quiere decir que estoy en la primera -- deshabilitar ir atras
       * 
       * si solo hay un -prev, -active 
       *  quiere decir que estoy en la ultima
       */

      /** note:
       * tambien puedo contar cuantas veces le ha dado click a la der o izq
       * si llego al final de cada uno paro
       */

      const button = hostElement.shadowRoot.querySelector(`.${nameClass}`) as HTMLElement;
      // if (nameClass == '')
      const [ ,, actionButton ] = nameClass.split('-');
      
      if ( actionButton == 'prev' && this.countClicks && this.countClicks != 1 ) {
        //no restare si es 0
        //note: ir hacia atrás
        
        /**
         * aqui se puede restar tantos numeros yo envíe
         */
        
        this.countClicks = this.countClicks - howManySlides;

      }
      else if ( actionButton == 'next' && this.countClicks < this.countSlides ) {
        //note: ir hacia adelante
        this.countClicks = this.countClicks + howManySlides;

      }

      console.log(this.countClicks);
      
      const prev = document.querySelector('.manage-move-prev') as HTMLElement;
      const next = document.querySelector('.manage-move-next') as HTMLElement;

      let canMove =  true;

      if ( this.countClicks && this.countClicks != 1 ) {
        console.log(prev);
        prev.style.opacity = '1'
        prev.style.filter = 'none'
        canMove = true

        if ( this.countClicks == this.countSlides ) {
          next.style.opacity = '0.5'
          // canMove = false
        }
        else if ( this.countClicks < this.countSlides ) {
          next.style.opacity = '1'
          canMove = true
        }
      } 
      else {
        prev.style.opacity = '0.5'
        // canMove = false;
      }

      console.log(this.countClicks, this.countSlides, {canMove});
      
      if ( canMove && !shadowMove ) {
        button.click()
      }
    }
  }

  navigateClickTest(nameClass:any) {

    const button = document.querySelector(`.${nameClass}`) as HTMLElement;

    button.click();

  }

  navigateClickMobile( nameClass: any ) {
    const hostElement = document.getElementById('swiperContainerMobile');
    if (hostElement?.shadowRoot) {
      const button = hostElement.shadowRoot.querySelector(`.${nameClass}`) as HTMLElement;
      
      if ( button ) {
        button.click()
        button.addEventListener('click', function(event){
          console.log(event);
        })
      }
    }
  }
  // onSlideChangeStart
  onSlideChangeStart (event: any) {

    const index = event.target.swiper.activeIndex;

    // this.navigateClick('swiper-button-next', true, index);
  }

  onSlideChangeEnd(event: any): void {
    console.log('Slide change ended', event);
  }

  onSlideChange(event:any) {
    console.log(event);
  }

  onBlur(event: any): void {
    console.log(event);

    event.preventDefault();
    event.stopPropagation();
    
    const identificationNumberForm = this.subscriberNgForm.get('identificationNumber');
    const identificationNumber = event.target.value;

    console.log({identificationNumber});
    


    if ( this.wichFlagIs ) {
      if ( this.wichFlagIs === this.defaultCountryCodeVenezuela  ) {
        if (identificationNumber.length <= 3) {
          identificationNumberForm!.setErrors({ 'lengthLocal': true });
        }
      }
      else {
      }
    }
    else {
      if (identificationNumber.length <= 3) {
        identificationNumberForm!.setErrors({ 'lengthLocal': true });
      }
    }

    
  }


  validateLength(event: any, type: any) {
    const length = lengthTypeNumber[type];
    const howMany = event.target.value.length;
    let validate =  true;

    console.log({howMany}); 
    if ( howMany === 30 ) {
      validate = false
    }
    return validate
  }

  validateInput() {
    const identificationNumber = this.subscriberNgForm.get('identificationNumber');
    console.log(identificationNumber);

    identificationNumber?.setErrors(null);

    if ( identificationNumber ) {
      const value = identificationNumber.value;
      
      if ( this.wichFlagIs === this.defaultCountryCodeVenezuela ) {
        if ( value.length < 5 || value.length > 8 || isNaN(value) ) {
          identificationNumber.setErrors({ 'lengthLocal': true });
        }
      }
      else {
        if ( value.length < 0 || value.length > 50 ) {
          identificationNumber.setErrors({ 'lengthForeign': true });
        }
      }
    }
  }

  validateNumber(event: any) {
    //tecla seleccionada
    const keyValue = event.key;
    //valor del input
    const identificationNumber = event.target.value;

    //teclas que no se validan
    if ( this.nonValidateKeys.includes(keyValue) ) {
      return true
    }
    

    
    //si hay una bandera seleccionada
    if ( this.wichFlagIs ) {
      if ( this.wichFlagIs === this.defaultCountryCodeVenezuela  ) {

        if ( !this.nonValidateKeys.includes(keyValue) && isNaN(keyValue) ) {
          return false
        }

        if ( identificationNumber.length  >= 9 ) {
          return false
        }
      }
      else {
        if ( identificationNumber.length >= 50 ) {
          return false
        }
      }
    }
    else {
      if ( !this.nonValidateKeys.includes(keyValue) && isNaN(keyValue) ) {
        return false
      }
      
      if ( identificationNumber.length >= 9 ) {
        return false
      }
    }

    return true
  }

  selectCountry(selectedValue: string, urlIcon: string, $event: any, id: string) {
    $event.preventDefault();
    $event.stopPropagation();    

    if ( selectedValue ) {
      this.idFlag = id;
      selectedValue =  selectedValue.split('+')[1];
      this.wichFlagIs = selectedValue;
      this.selectedFlag = urlIcon;
      this.subscriberNgForm.get('countryCode')?.setValue(selectedValue);
      this.subscriberNgForm.get('countryCode')?.updateValueAndValidity();
      this.validateInput();
    }
  }

  addClassToPreviousElement(): void {
    const labels = document.querySelectorAll('.label-select-resource');
    // const previousElement = labels.item(0)?.parentElement
    console.log(labels);
    // console.log(previousElement);
    
    
    // labels.forEach(label => {
      // const previousElement = label.previousElementSibling;
      // if (previousElement && previousElement.classList.contains('mat-mdc-floating-label')) {
        // previousElement.classList.add('custom-class');
      // }
    // });
  }

  prevent($event: any) {
    $event.preventDefault();
    $event.stopPropagation();
  }
}
